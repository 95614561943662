@supports (font-variation-settings: normal) {
  @font-face {
    font-family: "Lexend";
    font-style: normal;
    font-weight: 100 900;
    font-display: swap;
    src: local('Lexend'), url("../fonts/Lexend-VariableFont_wght.ttf") format("truetype");
    font-named-instance: "Regular";
  }

  @font-face {
    font-family: "Lexend";
    font-style: italic;
    font-weight: 100 900;
    font-display: swap;
    src: local('Lexend'), url("../fonts/Lexend-VariableFont_wght.ttf") format("truetype");
    font-named-instance: "Italic";
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

#styled-scroll::-webkit-scrollbar {
  width: 4px;
  cursor: pointer;

}

#styled-scroll::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /*background: red;*/
}

#styled-scroll::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #dee3ed;
  /*outline: 1px solid slategrey;*/
}

/* For Firefox */
input[type="number"].no-arrows {
  -moz-appearance: textfield;
}

/* For Chrome, Safari, Edge, Opera */
input[type="number"].no-arrows::-webkit-inner-spin-button,
input[type="number"].no-arrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}